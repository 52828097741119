<template>
  <div class="main-container position-relative py-0 py-md-5">
    <div class="row py-5 justify-content-center">
      <div class="col-11 col-md-10 col-lg-9">
        <div class="row">
          <div class="col-auto mb-4">
            <p class="text-dark font-bold font-size-2">Tableau de bord</p>
          </div>
          <div class="col-auto ml-auto mb-4 center-vertically-flex">
            <p
              class="font-size-4 text-light text-underline pointer"
              @click="$authentification.logout()"
            >
              Se déconnecter
            </p>
          </div>
          <div class="col-12 mb-5">
            <a href="https://discord.gg/AsmEWjwnNC" target="_blank">
              <div class="btn btn-xl btn-outline-pink">ACCEDER AU DISCORD</div>
            </a>
          </div>
          <div class="col-12 mt-5 mb-4">
            <div class="row align-items-center">
              <div class="col-6 col-lg-5 col-xl-4">
                <p class="text-dark font-bold font-size-3">Avancement</p>
              </div>
              <div class="col-6 col-lg-5 col-xl-4">
                <progress-bar
                  color="#2b3345"
                  :percent="(globalPartsDone / globalNumberParts) * 100"
                ></progress-bar>
              </div>
            </div>
          </div>
          <div
            class="col-12 mb-3"
            v-for="(chapter, index) in chapters"
            :key="'p_' + index"
          >
            <div class="row align-items-center">
              <div class="col-6 col-lg-5 col-xl-4">
                <p
                  class="text-light font-size-4 pointer"
                  @click="$common.showChapterModal(chapter.id)"
                >
                  {{ chapter.name }}
                </p>
              </div>
              <div class="col-6 col-lg-5 col-xl-4">
                <progress-bar
                  :color="chapter.color"
                  :percent="
                    (chapter.numberPartsValidated / chapter.parts.length) * 100
                  "
                ></progress-bar>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5 mb-3 pt-5">
            <div class="row align-items-center">
              <div class="col-auto">
                <p class="text-dark font-bold font-size-3">
                  Cheat sheets débloquées ({{ cheatsheetsIDs.length }}/{{
                    globalNumberParts
                  }})
                </p>
              </div>
              <div class="col-auto" v-if="hasPartsInfo">
                <pdf-generator :parts-info="partsInfo"></pdf-generator>
              </div>
            </div>
          </div>
          <div class="col-12 mb-5" v-if="!hasPartsInfo">
            <p class="text-light font-size-4">Chargement des cheat sheets …</p>
          </div>
          <div class="col-12 mb-5" v-if="hasPartsInfo">
            <p class="text-light font-size-4">
              Vous débloquez une cheat sheet après chaque visionnage d'une
              nouvelle vidéo
            </p>
          </div>
          <div
            class="col-12 col-md-6 col-xl-4 mb-4"
            v-for="(chapter, index) in chapters"
            :key="'c_' + index"
          >
            <div
              v-if="hasPartsInfo"
              class="rounded w-100 p-3"
              :class="'bg-chapter-' + chapter.id"
            >
              <p class="text-white font-size-4 font-bold mb-3">
                {{ chapter.name }} ({{ chapter.numberPartsValidated }}/{{
                  chapter.parts.length
                }})
              </p>
              <div
                v-for="(part, index) in chapter.parts"
                :key="'part_' + index"
              >
                <p
                  class="text-white text-underline mb-2 pointer"
                  v-if="part.isValidated && hasPartsInfo"
                  @click="openCheatSheetModal(part.id)"
                >
                  {{ part.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.$common.getPageMeta();
  },
  jsonld() {
    return this.$common.getJsonLd();
  },
  data() {
    return {
      globalPartsDone: 0,
      globalNumberParts: 0,

      chapters: this.$common.getSummary().chapters,
      cheatsheetsIDs: [],

      hasPartsInfo: false,
      partsInfo: null,
      cheatsheets: null,
    };
  },
  methods: {
    openCheatSheetModal(partID) {
      this.$common.showCheatsheetModal(
        partID,
        this.partsInfo[partID].cheatsheet,
        this.partsInfo[partID].links
      );
    },
    setProgress() {
      for (var i = 0; i < this.chapters.length; i++) {
        this.globalNumberParts += this.chapters[i].parts.length;
        this.chapters[i].numberPartsValidated = 0;

        for (var j = 0; j < this.chapters[i].parts.length; j++) {
          if (
            this.$database.advancement.hasOwnProperty(
              this.chapters[i].parts[j].id
            ) &&
            this.$database.advancement[this.chapters[i].parts[j].id] == true
          ) {
            this.chapters[i].parts[j].isValidated = true;
            this.chapters[i].numberPartsValidated++;
            this.globalPartsDone++;

            this.cheatsheetsIDs.push(this.chapters[i].parts[j].id);
          }
        }
      }
    },
    getPartsInfo() {
      if (!this.cheatsheetsIDs.length) {
        this.hasPartsInfo = true;
        return;
      }
      this.$api.parts
        .getMultiInfo(this.cheatsheetsIDs)
        .then((res) => {
          this.partsInfo = res.data;
          this.hasPartsInfo = true;
        })
        .catch((e) => {
          return this.$router.push("/");
        });
    },
  },
  created: function () {
    this.setProgress();
    this.getPartsInfo();
  },
  mounted: function () {},
  destroyed() {},
};
</script>
